// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61800: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, black },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: black,
      color: white,
    },
    line: {
      display: 'block',
      '@media screen and (max-width: 1009px)': {
        margin: '20px 0',
      },
    },
    socialHide: {
      display: 'block',
      '@media screen and (max-width: 1009px)': {
        width: '100%',
      },
    },
    socialCont: {
      margin: '14px 0',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    newStyle: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 20,
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logosContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 20px 10px 0',
    },
    legal: {
      justifyContent: 'space-between',
      borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    },
    copyr: {
      width: '69%',
    },
    logoImg: {
      width: 'auto',
      height: '73.5px',
      '@media (max-width: 500px)': {
        height: '45px',
      },
    },
    headerMenu: {
      fontWeight: 'bold',
      '@media screen and (max-width: 1370px)': {
        fontWeight: 'bold',
      },
    },
    menuWrapper: {
      width: '60%',
      paddingBottom: 18,
      justifyContent: 'space-between',
    },
    sponsorWrapper: {
      display: 'none',
    },
    socialWrap: {
      display: 'none',
    },
    licenseLink: {
      '&:last-child': {
        paddingRight: 0,
      },
    },
    lineHide: {
      '@media screen and (max-width: 1009px)': {
        display: 'block',
      },
    },
    appsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '@media screen and (max-width: 1009px)': {
        justifyContent: 'center',
      },
    },
    googlePlay: {
      display: 'block',
      width: 115,
    },
    appGallery: {
      display: 'block',
      width: 115,
    },
    appGalleryImg: {
      width: '100%',
    },
  };
};
